import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import CustomButton from "../command/customButton/customButton";

const InquireCatalog = ({ show, onHide }) => {
  const [validated, setValidated] = useState(false);
  const [profession, setProfession] = useState(""); // To track the profession
  const [purpose, setPurpose] = useState(""); // To track the purpose
  const [formState, setFormState] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    profession: "",
    describeYourself: "",
    purpose: "",
    cityState: "",
    message: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });

    // Update profession or purpose for dynamic fields
    if (name === "profession") setProfession(value);
    if (name === "purpose") setPurpose(value);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    event.preventDefault();

    const dataToSubmit = {
      ...formState,
      describeYourself:
        profession === "Other" ? formState.describeYourself : "", // Conditionally include "Describe Yourself"
    };

    axios({
      method: "POST",
      url: "http://aiiyn.in/InquireCatalogPFrom.php", // Replace with your API endpoint
      data: dataToSubmit,
    })
      .then(() => {
        setShowSuccessMessage(true); // Show the success message
        setFormState({
          fullName: "",
          email: "",
          contactNumber: "",
          profession: "",
          describeYourself: "",
          purpose: "",
          cityState: "",
          message: "",
        });
        setProfession("");
        setPurpose("");
        setValidated(false);
      })
      .catch(() => {
        alert("Submission failed. Please try again.");
      });
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
    onHide(); // Close the modal after successful submission
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Inquire Catalog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              {/* Full Name */}
              <Form.Group className="col-md-6 form-group" controlId="fullName">
                <Form.Label>
                  Full Name<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="fullName"
                  value={formState.fullName}
                  onChange={onInputChange}
                  placeholder="Full Name"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Email Address */}
              <Form.Group className="col-md-6 form-group" controlId="email">
                <Form.Label>
                  Email Address<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={formState.email}
                  onChange={onInputChange}
                  placeholder="Email Address"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Contact Number */}
              <Form.Group
                className="col-md-6 form-group"
                controlId="contactNumber"
              >
                <Form.Label>
                  Contact Number<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="contactNumber"
                  value={formState.contactNumber}
                  onChange={onInputChange}
                  placeholder="Contact Number"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Profession */}
              <Form.Group
                className="col-md-6 form-group"
                controlId="profession"
              >
                <Form.Label>
                  Select Profession<span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="profession"
                  value={formState.profession}
                  onChange={onInputChange}
                >
                  <option value="">Choose...</option>
                  <option value="Architect">Architect</option>
                  <option value="Interior Designer">Interior Designer</option>
                  <option value="Product Designer">Product Designer</option>
                  <option value="Brand Owner">Brand Owner</option>
                  <option value="Furniture Enthusiast">
                    Furniture Enthusiast
                  </option>
                  <option value="Student">Student</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a profession.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Describe Yourself (if profession is "Other") */}
              {profession === "Other" && (
                <Form.Group
                  className="col-md-6 form-group"
                  controlId="describeYourself"
                >
                  <Form.Label>Describe Yourself</Form.Label>
                  <Form.Control
                    type="text"
                    name="describeYourself"
                    value={formState.describeYourself}
                    onChange={onInputChange}
                    placeholder="Describe Yourself"
                  />
                </Form.Group>
              )}

              {/* Purpose of Inquiry */}
              <Form.Group className="col-md-6 form-group" controlId="purpose">
                <Form.Label>
                  Purpose of Inquiry<span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="purpose"
                  value={formState.purpose}
                  onChange={onInputChange}
                >
                  <option value="">Choose...</option>
                  <option value="For order">For Order</option>
                  <option value="B2B">B2B</option>
                  <option value="Personal Use">Personal Use</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a purpose.
                </Form.Control.Feedback>
              </Form.Group>

              {/* City / State */}
              <Form.Group className="col-md-6 form-group" controlId="cityState">
                <Form.Label>
                  City / State<span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="cityState"
                  value={formState.cityState}
                  onChange={onInputChange}
                  placeholder="City / State"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city/state.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Message Box */}
              <Form.Group className="col-12 form-group" controlId="message">
                <Form.Label>Tell us what you are looking for...</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formState.message}
                  onChange={onInputChange}
                  placeholder="Message"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              {/* Submit Button */}
              <Form.Group className="col-md-4 offset-md-4 form-group mb-0">
                <CustomButton type="submit" btnText="Submit Form" />
              </Form.Group>
            </div>
          </Form>
          {/* Success Message Overlay */}
          {showSuccessMessage && (
            <div className="success-overlay" onClick={closeSuccessMessage}>
              <div className="success-message">
                <h3>Thank You!</h3>
                <p>
                  Thank you for reaching out and requesting our catalogue! We
                  appreciate your interest in our products/services. If you have
                  any specific questions or need further assistance in the
                  meantime, please don’t hesitate to get in touch. We look
                  forward to connecting with you soon and hope our catalogue
                  provides everything you’re looking for!
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InquireCatalog;
