import React, { useEffect } from "react";
import WOW from "wowjs";
import "animate.css";
import "./homePage.scss";
import {
  AboutSection,
  AiynCovalenceSection,
  BannerSlider,
  ProductSection,
  ServiceSection,
} from "../../components";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  useEffect(() => {
    // Initialize WOW.js
    new WOW.WOW({
      live: false, // Disable continuous DOM checking for better performance
    }).init();
  }, []);

  return (
    <div>
      <div className="wow animate__animated animate__fadeInLeft">
        <BannerSlider />
      </div>
      <div className="wow animate__animated animate__fadeInRight">
        <AboutSection />
      </div>
      <div className="wow animate__animated animate__fadeInLeft">
        <ProductSection />
      </div>
      <div className="wow animate__animated animate__fadeInRight">
        <ServiceSection />
      </div>
      <div className="wow animate__animated animate__fadeInLeft">
        <AiynCovalenceSection />
      </div>
    </div>
  );
};

export default HomePage;
