import React, { useState, useEffect } from "react";
import "./header.scss";
import {
  Button,
  Container,
  Form,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function MyNavbar() {
  const location = useLocation();

  return (
    <Nav className="justify-content-end flex-grow-1 pe-3">
      <NavItemLink to="/" currentPath={location.pathname}>
        Home
      </NavItemLink>
      <NavItemLink to="/aboutus" currentPath={location.pathname}>
        About Us
      </NavItemLink>
      <NavItemLink to="/all-products" currentPath={location.pathname}>
        All Products
      </NavItemLink>
      <NavItemLink to="/career" currentPath={location.pathname}>
        Career
      </NavItemLink>
      <NavItemLink to="/contactus" currentPath={location.pathname}>
        Contact Us
      </NavItemLink>
    </Nav>
  );
}

function NavItemLink({ to, currentPath, children }) {
  const isActive = currentPath === to;
  return (
    <Nav.Link
      as={Link}
      to={to}
      className={isActive ? "nav-link active" : "nav-link"}
    >
      {children}
    </Nav.Link>
  );
}

const Header = () => {
  const [show, setShow] = useState(false);
  const [scrolled, setScrolled] = useState(false); // Track scroll state
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Close sidebar when route changes
  useEffect(() => {
    handleClose();
  }, [location]);

  // Add or remove class based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={scrolled ? "scrolled" : ""}>
      <Navbar bg="light" expand={false} sticky="top">
        <div className="container-lg">
          <Link to={"/"} className="logo">
            aiiyn
          </Link>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <MyNavbar />
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
