import React from "react";
import {
  AppointmentFormContact,
  BreadcrumbSec,
  ContactUsForm,
  PageTitleImage,
} from "../../components/command";
import AiynCovalenceSection from "../../components/aiynCovalenceSection /aiynCovalenceSection";

const ContactUsPage = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Contact us", href: "/" },
  ];

  return (
    <div className="contact-us-page">
      <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
      <PageTitleImage
        PageTitleImg="/assets/images/Contact-Us.jpg"
        PageTitleMain="Contact us"
        // PageTitleContent="  We're seeking a talented crew of creative minds to collaborate
        //         with us in weaving stories through furniture and form. Whether
        //         you're a seasoned designer or a fledgling artist with a
        //         burgeoning passion, we have an opportunity waiting to ignite
        //         your spark."
      />
      <ContactUsForm />
      <AppointmentFormContact />
      <AiynCovalenceSection />
    </div>
  );
};

export default ContactUsPage;
