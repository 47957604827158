import React from "react";
import "./pageTitleImage.scss";

const PageTitleImage = ({ PageTitleImg, PageTitleMain, PageTitleContent }) => {
  return (
    <section className="pagetitle-banner-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="pagetitle-banner-content">
              <img src={PageTitleImg} className="img-fluid" />
              <h2>{PageTitleMain}</h2>
              <p>{PageTitleContent}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleImage;
