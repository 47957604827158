import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Link } from "react-router-dom";
import DesignDetailsModal from "./designDetailsModal";
import MaterialAndCare from "./materialAndCare";
import PurchaseGuidelinesModal from "./purchaseGuidelinesModal";
import "./productDetails.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import CustomButton from "../command/customButton/customButton";
import InquireCatalog from "../commandForm/inquireCatalog";

const ProductDetails = ({
  productImages = [],
  productDName = "",
  productDCode = "",
  weightOfProduct = "",
  productDDetails = [],
  customisationChart = [],
}) => {
  const [designDetails, setDesignDetails] = useState(false);
  const [materialCare, setMaterialCare] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [inquireCatalogModalShow, setInquireCatalogModalShow] =
    React.useState(false);
  const [customSwiper, setCustomSwiper] = useState(null);

  return (
    <>
      <section className="product-details-section">
        <div className="container-lg">
          <div className="row gx-5">
            <div className="col-lg-6">
              <Swiper
                spaceBetween={0}
                navigation={true}
                modules={[FreeMode, Navigation, Thumbs]}
                thumbs={{
                  swiper:
                    customSwiper && !customSwiper.destroyed
                      ? customSwiper
                      : null,
                }}
                className="product-slider"
              >
                {productImages.map((imgItem, index) => (
                  <SwiperSlide key={index}>
                    <Zoom>
                      <img
                        className="img-fluid main-slider-img"
                        alt={`Main ${imgItem}`}
                        src={imgItem}
                      />
                    </Zoom>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setCustomSwiper}
                spaceBetween={20}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                  },
                  576: {
                    slidesPerView: 3,
                  },
                }}
                className="product-nav-slider"
              >
                {productImages.map((imgItem, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="img-fluid nav-slider-img"
                      alt={`Thumbnail ${imgItem}`}
                      src={imgItem}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-lg-6">
              <div className="product-details-content">
                <div className="product-title">
                  <h3 className="product-title-name">{productDName}</h3>
                  <h3 className="product-title-code">{productDCode}</h3>
                </div>
                <div className="product-d-content">
                  {productDDetails.map((detail, index) => (
                    <p key={index}>{detail}</p>
                  ))}
                </div>
                <div className="product-details-links">
                  <div className="row">
                    <div className="col-md-4">
                      <Link
                        to={"#"}
                        onClick={() => setDesignDetails(true)}
                        className="details-links"
                      >
                        <i class="fa-solid fa-plus"></i>Design Details and
                        Customisation
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <Link
                        to={"#"}
                        onClick={() => setMaterialCare(true)}
                        className="details-links"
                      >
                        <i class="fa-solid fa-plus"></i>Material and Care
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <Link
                        to={"#"}
                        onClick={() => setPurchaseModal(true)}
                        className="details-links"
                      >
                        <i class="fa-solid fa-plus"></i>Purchase Guidelines
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <CustomButton
                      onClick={() => setInquireCatalogModalShow(true)}
                      btnText="Inquire catalog"
                      btnClass="mt-3 fill-width"
                    />
                  </div>
                </div>
                {/* <div className="customisation-chart">
                  <h3>Customisation Chart</h3>
                  <div className="customisation-chart-table">
                    <table border="0">
                      <tbody>
                        {customisationChart.map((item, index) => {
                          if (index >= 2 && index <= 4) {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{item.attribute}</td>
                                  <td>{item.customizable}</td>
                                  {index === 2 && (
                                    <td rowSpan={3} colSpan={2}>
                                      {customisationChart
                                        .slice(2)
                                        .map((i) => i.description)
                                        .filter((d) => d)
                                        .join(" ")}
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            );
                          }
                          return (
                            <tr key={index}>
                              <td>{item.attribute}</td>
                              <td>{item.customizable}</td>
                              <td colSpan={2}>{item.description}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="offset-0 col-md-12">
              <div className="customisation-chart-table">
                <div className="customisation-chart">
                  <h3>Customisation Chart</h3>
                  <div className="customisation-chart-table">
                    <table border="0">
                      <tbody>
                        {customisationChart.map((item, index) => {
                          if (index >= 2 && index <= 4) {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td
                                    style={{
                                      width: "180px",
                                      fontFamily: "strawfordmedium",
                                    }}
                                  >
                                    {item.attribute}
                                  </td>
                                  <td>{item.customizable}</td>
                                  {index === 2 && (
                                    <td
                                      rowSpan={3}
                                      colSpan={2}
                                      style={{ textAlign: "left" }}
                                    >
                                      {customisationChart
                                        .slice(2)
                                        .map((i) => i.description)
                                        .filter((d) => d)
                                        .join(" ")}
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            );
                          }
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  width: "180px",
                                  fontFamily: "strawfordmedium",
                                }}
                              >
                                {item.attribute}
                              </td>
                              <td style={{ width: "100px" }}>
                                {item.customizable}
                              </td>
                              <td colSpan={2} style={{ textAlign: "left" }}>
                                {item.description}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DesignDetailsModal
        show={designDetails}
        onHide={() => setDesignDetails(false)}
        weightOfProduct={weightOfProduct}
      />
      <MaterialAndCare
        show={materialCare}
        onHide={() => setMaterialCare(false)}
      />
      <PurchaseGuidelinesModal
        show={purchaseModal}
        onHide={() => setPurchaseModal(false)}
      />
      <InquireCatalog
        show={inquireCatalogModalShow}
        onHide={() => setInquireCatalogModalShow(false)}
      />
    </>
  );
};

ProductDetails.defaultProps = {
  productImages: [],
  productDDetails: [],
};

export default ProductDetails;
