import React from "react";
import "./bannerSlider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import CustomButton from "../command/customButton/customButton";
import { useNavigate } from "react-router-dom";

const BannerSlider = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/all-products");
  };

  return (
    <div className="banner-slider-section">
      <div className="banner-slide">
        <div className="banner-slider-box">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 p-0">
                <Swiper
                  modules={[Pagination, Autoplay]}
                  className="mySwiper"
                  pagination={{ clickable: true }} // Enables dot navigation
                  autoplay={{
                    delay: 5000, // Set delay between slides (3 seconds)
                    disableOnInteraction: false, // Autoplay won't stop on user interaction
                  }}
                >
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H1.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H2.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H3.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H4.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H5.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H6.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H7.jpg"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="banner-image">
                      <img
                        alt="banner-image"
                        src="/assets/images/banner-images/H8.jpg"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="col-md-5 p-0">
                <div className="banner-content">
                  <h3>
                    <span>Welcome to Aiiyn,</span> where we aspire to connect
                    with you through our range of handcrafted products,
                    carefully designed to create a luxurious lifestyle.
                  </h3>{" "}
                  <h3>
                    <span> Introducing Arka</span> – the soul of our brand,
                    marking the beginning of our journey. A collection that
                    reflects our passion, vision, and craftsmanship, crafted to
                    elevate the heart of your home.
                  </h3>
                  <CustomButton
                    btnText="Explore All Products"
                    btnClass="home-button"
                    onClick={handleButtonClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
