import React from "react";
import { BreadcrumbSec, ProductCard } from "../../components/command";
import products from "../../product.json";

const AllProductsPage = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "All Products", href: "/" },
  ];

  return (
    <div className="all-products-page">
      <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
      <section className="all-product section-space">
        <div className="container-lg">
          <div className="row gx-5">
            {products.map((productsData) => {
              const firstImage =
                productsData.productImage &&
                productsData.productImage.length > 0
                  ? productsData.productImage[0]
                  : "./path/to/placeholder.jpg";
              return (
                <div className="col-md-4" key={productsData.id}>
                  <ProductCard
                    productCardName={productsData.productName}
                    productCardCode={productsData.code}
                    imagePath={firstImage}
                    productDetailsLink={`/products/${productsData.id}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllProductsPage;
