import React from "react";
import "./footer.scss";
import { Form, Navbar } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Mail,
  Phone,
  Facebook,
  Instagram,
  Twitter,
  Linkedin,
  Send,
  PhoneCall,
} from "react-feather";
import InquireCatalog from "../../commandForm/inquireCatalog";

const Footer = () => {
  const [inquireCatalogModalShow, setInquireCatalogModalShow] =
    React.useState(false);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setInquireCatalogModalShow(true); // Show the modal
  };

  return (
    <>
      <footer>
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-3 col-sm-6 footer-col">
              <div className="footer-brand-box">
                <Navbar.Brand href="#" className="logo">
                  aiiyn
                </Navbar.Brand>
                <h4 className="footer-brand-text">Furniture design studio</h4>

                <div className="social-media-links">
                  <a
                    className="footer-link"
                    href={"https://www.facebook.com/aiiyn.india/"}
                    target="_blank"
                  >
                    <Facebook />
                  </a>
                  <a
                    className="footer-link"
                    href={"https://www.instagram.com/aiiyn.in/"}
                    target="_blank"
                  >
                    <Instagram />
                  </a>
                  {/* <a
                  className="footer-link"
                  href={
                    "https://www.instagram.com/aiyn.in/?igsh=MXd6OTNuZWllMTc2YQ%3D%3D&utm_source=qr"
                  }
                  target="_blank"
                >
                  <Linkedin /> 
                </a> */}
                  <a
                    className="footer-link"
                    href={
                      "https://api.whatsapp.com/send/?phone=%2B919998461209&text=Hey%2C%0AI+would+like+to+inquire+about+your+catalog%21%0A&type=phone_number&app_absent=0"
                    }
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      src="/assets/images/icon/whatappB.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-col">
              <h4 className="footer-title">Links</h4>
              <div className="footer-nav-links">
                <Link className="footer-link" to={"aboutus"}>
                  About us
                </Link>
                <Link className="footer-link" to={"contactus"}>
                  Contact
                </Link>
                <Link className="footer-link" to={"career"}>
                  Career
                </Link>
                <Link className="footer-link" to={"contactus"}>
                  Book an appointment for studio visit
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-col">
              <h4 className="footer-title">Links</h4>
              <div className="footer-nav-links">
                <a className="footer-link" href="#" onClick={handleClick}>
                  Catalog
                </a>
                <a className="footer-link" href="#" onClick={handleClick}>
                  Material & Care
                </a>
                <a className="footer-link" href="#" onClick={handleClick}>
                  Purchase guideline
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footer-col">
              <div className="footer-subscribe">
                <h4 className="footer-title">Subscribe</h4>
                <Form>
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <div className="email-address">
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                      />
                      <button>
                        <Send />
                      </button>
                    </div>
                  </Form.Group>
                </Form>
                <div className="footer-contact">
                  <div className="contact-info">
                    <PhoneCall />
                    <h5>+91 99984 61209</h5>
                  </div>
                  <div className="contact-info">
                    <Mail />
                    <h5>info@aiiyn.in</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <InquireCatalog
        show={inquireCatalogModalShow}
        onHide={() => setInquireCatalogModalShow(false)}
      />
    </>
  );
};

export default Footer;
