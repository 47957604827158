import { EMLead, GraphicDesigner } from "../../components";
import {
  BreadcrumbSec,
  CareerCard,
  JuniorDesigner,
  PageTitleImage,
} from "../../components/command";
import "./careerPage.scss";
import React from "react";

const CareerPage = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [juniorShow, setJuniorShow] = React.useState(false);
  const [eMLeadShow, setEMLeadShow] = React.useState(false);
  const ExecutionManagementPoints = [
    "Full time",
    "Based in Surat",
    "0-2 years of Experience ",
    "Proficiency in AutoCAD",
    "Execution, Communication and Management skills",
  ];
  const JuniorDesignerPoints = [
    "Full time",
    "Based in Surat",
    "0-2 years of Experience",
    "Architect / Interior designer / Furniture designer / Product designer",
    "Proficiency in AutoCAD, any 3d software, Microsoft suite",
  ];
  const GraphicDesignerPoints = [
    "Freelancing / Full time",
    "0-2 years of Experience",
  ];
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Career", href: "/" },
  ];
  return (
    <>
      <div className="career-page ">
        <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
        <PageTitleImage
          PageTitleImg="/assets/images/Career.jpg"
          PageTitleMain="Career"
          // PageTitleContent="  We're seeking a talented crew of creative minds to collaborate
          //       with us in weaving stories through furniture and form. Whether
          //       you're a seasoned designer or a fledgling artist with a
          //       burgeoning passion, we have an opportunity waiting to ignite
          //       your spark."
        />
        <section className="career-card-section section-space">
          <div className="container">
            <div className="row gx-5">
              <div className="col-md-4">
                <CareerCard
                  careerCardTitle="Execution and Management Lead"
                  careerCardDetails="Think of yourself as the maestro of the design orchestra, ensuring every note translates flawlessly from concepts to reality. We are looking forward to someone with a keen eye on detail, seamless execution skills and proficiency in manufacturing management."
                  cardPointsArray={ExecutionManagementPoints}
                  careerEvent={() => setEMLeadShow(true)}
                />
              </div>
              <div className="col-md-4">
                <CareerCard
                  careerCardTitle="Junior Designer"
                  careerCardDetails="Calling all aspiring Architects, Interior designers, Furniture designers, Product designers and Design enthusiasts. We're searching for someone with a curious mind and a heart that beats for beautiful and functional designs."
                  cardPointsArray={JuniorDesignerPoints}
                  careerEvent={() => setJuniorShow(true)}
                />
              </div>
              <div className="col-md-4">
                <CareerCard
                  careerCardTitle="Graphic Designer and  3D Visualizer"
                  careerCardDetails="Do you possess the alchemy to transform ideas into visuals that captivate? While experience is valued, raw talent and desire to leave your creative mark are the true treasures we seek."
                  cardPointsArray={GraphicDesignerPoints}
                  careerEvent={() => setModalShow(true)}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="career-page-note">
                <p>
                  Escape the mundane with us. At Aiyn, you'll be joining a
                  collaborative family where creativity thrives, intellectual
                  curiosity is encouraged, and your ideas have the power to
                  become reality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Graphic Designer */}
      <GraphicDesigner show={modalShow} onHide={() => setModalShow(false)} />
      {/* Junior Designer */}
      <JuniorDesigner show={juniorShow} onHide={() => setJuniorShow(false)} />
      {/* Execution and Management Lead */}
      <EMLead show={eMLeadShow} onHide={() => setEMLeadShow(false)} />
    </>
  );
};

export default CareerPage;
