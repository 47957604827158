import React, { useState } from "react";
import "./appointmentFormContact.scss";
import MainTitle from "../mainTitle/mainTitle";
import { Form } from "react-bootstrap";
import CustomButton from "../customButton/customButton";
import axios from "axios";

const AppointmentFormContact = () => {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState({
    fullName: "",
    personalEmail: "",
    professionalEmail: "",
    contactNumber: "",
    numberOfVisitors: "",
    areaCityState: "",
    preferableDate: "",
    message: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Make the API call with axios
      axios({
        method: "POST",
        url: "https://aiiyn.com/appointmentFormPForm.php", // Replace with your API endpoint
        data: formState,
      })
        .then((response) => {
          // Show success message and reset the form
          setShowSuccessMessage(true);
          setFormState({
            fullName: "",
            personalEmail: "",
            professionalEmail: "",
            contactNumber: "",
            numberOfVisitors: "",
            areaCityState: "",
            preferableDate: "",
            message: "",
          });
          setValidated(false);
        })
        .catch((error) => {
          console.error("There was an error submitting the form:", error);
          setShowErrorMessage(true);
        });
    }

    setValidated(true);
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      <section className="appointment-form-section home-section-space">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="appointment-form-map-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="appointment-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3720.7753236073454!2d72.98842557526031!3d21.16133778052134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA5JzQwLjgiTiA3MsKwNTknMjcuNiJF!5e0!3m2!1sen!2sin!4v1733063918154!5m2!1sen!2sin"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="appointment-form">
                      <MainTitle mainTitle="Book an appointment for Studio visit" />
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          {/* Full Name */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="fullName"
                          >
                            <Form.Label>Full Name *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="fullName"
                              value={formState.fullName}
                              onChange={onInputChange}
                              placeholder="Full Name"
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Personal Email */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="personalEmail"
                          >
                            <Form.Label>Personal Email *</Form.Label>
                            <Form.Control
                              required
                              type="email"
                              name="personalEmail"
                              value={formState.personalEmail}
                              onChange={onInputChange}
                              placeholder="Personal Email"
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Professional Email */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="professionalEmail"
                          >
                            <Form.Label>Professional Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="professionalEmail"
                              value={formState.professionalEmail}
                              onChange={onInputChange}
                              placeholder="Professional Email"
                            />
                          </Form.Group>

                          {/* Contact Number */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="contactNumber"
                          >
                            <Form.Label>Contact Number *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="contactNumber"
                              value={formState.contactNumber}
                              onChange={onInputChange}
                              placeholder="Contact Number"
                            />
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Number of Visitors */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="numberOfVisitors"
                          >
                            <Form.Label>
                              Number of People Visiting the Studio *
                            </Form.Label>
                            <Form.Control
                              as="select"
                              required
                              name="numberOfVisitors"
                              value={formState.numberOfVisitors}
                              onChange={onInputChange}
                            >
                              <option value="">Select</option>
                              <option value="1-2">1-2</option>
                              <option value="2-5">2-5</option>
                              <option value="5-10">5-10</option>
                              <option value="more">More than 10</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please select the number of visitors.
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Area / City / State */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="areaCityState"
                          >
                            <Form.Label>
                              Area/City/State You’re Visiting From *
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="areaCityState"
                              value={formState.areaCityState}
                              onChange={onInputChange}
                              placeholder="City / State"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid city/state.
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Preferable Date */}
                          <Form.Group
                            className="col-md-6 form-group"
                            controlId="preferableDate"
                          >
                            <Form.Label>Your Preferable Date/Day *</Form.Label>
                            <Form.Control
                              required
                              type="date"
                              name="preferableDate"
                              value={formState.preferableDate}
                              onChange={onInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please select a date.
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* Message */}
                          <Form.Group
                            className="col-12 form-group"
                            controlId="message"
                          >
                            <Form.Label>Write a Message</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="message"
                              value={formState.message}
                              onChange={onInputChange}
                              placeholder="Share your thoughts"
                            />
                          </Form.Group>
                        </div>

                        <Form.Group className="col-md-6 offset-md-4 form-group mb-0">
                          <CustomButton
                            btnClass="home-button"
                            btnText=" Submit Form"
                          />
                        </Form.Group>
                      </Form>
                      {/* Success Message Box */}
                      {showSuccessMessage && (
                        <div
                          className="success-overlay"
                          onClick={closeSuccessMessage}
                        >
                          <div className="success-message">
                            <h3>Thank you!</h3>
                            <p>
                              Thank you for booking an appointment to visit our
                              studio! We’re excited to welcome you and share our
                              designs with you. Your request has been received,
                              and we’ll confirm the details shortly. If you have
                              any specific questions or special requests before
                              your visit, feel free to reach out. We look
                              forward to meeting you soon and sharing our
                              creative space with you!
                            </p>
                          </div>
                        </div>
                      )}

                      {/* Error Message Box */}
                      {/* {showErrorMessage && (
                        <div className="error-message-box">
                          <div className="message-content">
                            <span>
                              There was an error submitting your form. Please
                              try again!
                            </span>
                            <button
                              className="close-btn"
                              onClick={closeErrorMessage}
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppointmentFormContact;
