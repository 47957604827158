import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import CustomButton from "../command/customButton/customButton";
import "./careerModal.scss";

const EMLead = ({ show, onHide }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal-form"
    >
      <Modal.Header closeButton>
        <Modal.Title>Execution and Management Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <Form.Group
              className="col-12 form-group"
              controlId="validationCustom01"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" placeholder="Name" />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-md-6 form-group"
              controlId="validationCustom02"
            >
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control required type="text" placeholder="Mobile Number" />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-md-6 form-group"
              controlId="validationCustom03"
            >
              <Form.Label>Email Id</Form.Label>
              <Form.Control required type="email" placeholder="Email Id" />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-md-6 form-group"
              controlId="validationCustom04"
            >
              <Form.Label>City / State</Form.Label>
              <Form.Control type="text" placeholder="City / State" required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid city/state.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-md-6 form-group"
              controlId="validationCustom05"
            >
              <Form.Label>Attach Resume</Form.Label>
              <Form.Control type="file" required />
              <Form.Control.Feedback type="invalid">
                Please attach your resume.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-12 form-group"
              controlId="validationCustom06"
            >
              <Form.Label>Add Website</Form.Label>
              <Form.Control type="text" placeholder="Add Website" />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group
            className="col-md-4 offset-md-4 form-group mb-0"
            controlId="validationCustom06"
          >
            <CustomButton type="submit" btnText="Submit form" />
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EMLead;
