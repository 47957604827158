import React from "react";
import "./aboutUsPage.scss";
import {
  BreadcrumbSec,
  MainTitle,
  PageTitleImage,
  SubTitle,
} from "../../components/command";

const AboutUsPage = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "About us", href: "/" },
  ];

  return (
    <div className="about-us-page">
      <BreadcrumbSec BreadcrumbArray={breadcrumbItems} />
      <PageTitleImage
        PageTitleImg="/assets/images/About-Us.jpg"
        PageTitleMain="About Us"
        // PageTitleContent="  We're seeking a talented crew of creative minds to collaborate
        //         with us in weaving stories through furniture and form. Whether
        //         you're a seasoned designer or a fledgling artist with a
        //         burgeoning passion, we have an opportunity waiting to ignite
        //         your spark."
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="about-section">
              <SubTitle subTitle="Our Intent" />
              <div className="about-description-box">
                <h5 className="point-title">
                  ‘Our homes are an extension of ourselves.’
                </h5>
                <p className="about-description">
                  They play a very imperative role in shaping our lives, they
                  are an annex of our manifestations turned to reality. Every
                  facet of our existence is impacted by design, and it has
                  permeated itself into our way of life. Our designs aspire to
                  go beyond gratifying the aesthetics, an ode to knit the
                  narratives of your life.
                </p>
              </div>
              <div className="about-description-box">
                <h5 className="point-title">Weaving stories in teak</h5>
                <p className="about-description">
                  We believe in the power of functionality, ensuring every piece
                  serves a purpose with timeless elegance. We are storytellers,
                  translating your aspirations into reality through the language
                  of form, material, and texture. The rich hues and enduring
                  character of teak become the canvas on which your story
                  unfolds.
                </p>
              </div>
              <div className="about-description-box">
                <h5 className="point-title">Celebrating Indian Homes</h5>
                <p className="about-description">
                  We are deeply rooted in the rich heritage of Indian design. We
                  draw inspiration from the past while embracing newness,
                  establishing a design language that is both contemporised and
                  distinctly Indian. Our furniture is a celebration of culture,
                  craftsmanship, and stories passed down through generations.
                </p>
              </div>
              <SubTitle subTitle="Our Approach" />
              <div className="about-description-box">
                <p className="about-description">
                  Crafting a home is a very personal and cherished moment for
                  the family. We at Aiyn, believe to confluence with you in
                  sculpting your space with passion and devoir.
                </p>
              </div>
              <h5 className="highlight-points">
                "We celebrate the unnoticed beauty of everyday life, the quiet
                moments that weave the fabric of your existence.""
              </h5>
              <div className="about-description-box">
                <p className="about-description">
                  Every Aiyn creation is an ode to{" "}
                  <span>balance and functionality</span>. We create ergonomic
                  design, ensuring each piece complements your movements and
                  fosters a sense of belonging and effortless comfort. Visual
                  harmony is paramount, with a meticulous attention to palettes,
                  textures, and the interplay between macro and micro elements.
                </p>
                <p className="about-description">
                  Aiyn furniture isn't simply designed; it's an invitation. We
                  create pieces that adapt and evolve with your needs,
                  transforming to compliment your unique lifestyle. Our
                  furniture is a <span>canvas for your creativity</span>, a
                  platform for you to tell your story and express yourself
                  through everyday use.
                </p>
                <p className="about-description">
                  We work along with skilled craftsmen, their dedication and
                  expertise forming the backbone of our design philosophy. We
                  utilize teak intelligently, maximizing its potential to create
                  pieces with an unwavering{" "}
                  <span>commitment to quality and craftsmanship</span>.
                </p>
                <p className="about-description">
                  <span>Contemporizing tradition</span> is at the heart of our
                  approach, ensuring our designs remain both timeless and
                  relevant for generations to come.
                </p>
              </div>
              <SubTitle subTitle="About the Founder" />
              <h5 className="highlight-points">
                “Our spaces can change the way we perceive the world, enriching
                these spaces through design can enhance the symphony of our
                being.”
              </h5>
              <p className="about-description">
                <span>Saloni Parekh,</span> the founder of Aiyn, is an
                architectural designer by profession. Her journey as an
                architecture student evoked in her a passion for design details
                and material exploration. With critical thinking and a keen eye
                for detail, she thrives on creating intelligent and exploratory
                designs. Saloni believes in the value of hard work and staying
                true to her roots. By knitting Indian narratives into her
                designs, she tells stories and creates products that paved the
                way for her to establish her own design studio, Aiyn.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
